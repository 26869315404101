<!-- Keypadmem.svelte -->

<script lang="ts">
  //Note: Don't use memoryId, some memories don't have that yet, as they are not in the database

  import type { SvelteComponent } from "svelte";

  import { BatchLessonData, BatchMemory, AuthorizedForLessonVm } from "../models/interfaces"; //TODO try to get rid of some of these
  import { fetchGet, fetchPost } from "../services/fetch";
  import { minutesUntilAuthorized, secondTimer } from "../services/stores";
  import { navigate } from "svelte-routing";
  import { onMount, onDestroy, tick } from "svelte";
  import { toast } from "@zerodevx/svelte-toast";
  import { userinput, showDailyLimitAlert, isMobile, mathContent, showInstructions, rating } from "../services/stores";
  import { WaitTimes } from "./WaitTimes";
  import { dateUtc, DateUtc } from "../services/DateUtc";

  import GameUnlocked from "../GameUnlocked.svelte";
  import SwitchButton from "./SwitchButton.svelte";
  import KeysEq from "./KeysEq.svelte";
  import Keys from "./Keys.svelte";
  import MathView from "./MathView.svelte";
  import MemoryTaskView from "../Keypadmem/MemoryTaskView.svelte";

  import { loadMath, simplifiedEqual } from "./Equation";
  import TestIntroPopUp from "./TestIntroPopUp.svelte";
  import MemoryTimer from "./MemoryTimer.svelte";
  import TestOutroPopup from "./TestOutroPopup.svelte";
  import LevelCompletePopup from "./LevelCompletePopup.svelte";
  import { updateRating } from "../services/Contact";
  import { atobUtf8 } from "../services/text";

  export let slug: any;
  export let achievedNewProgressProperty = false;

  let authorized = false;
  let currentTask: BatchMemory | null;
  let finished = false; //More questions
  let hintExists = false;
  let infoText = "";
  let inputElement: HTMLInputElement | null = null;
  let InstructionComponent: typeof SvelteComponent | null = null;
  let isEquationEditor = false;
  let isGameOver = false;
  let isShowHighScore = false;
  let lessonData: BatchLessonData;
  let lessonDescription = "Loading";
  let memories: BatchMemory[] = [];
  let paramA: string = "-1";
  let paramB: string = "-1";
  let questionStr: string | undefined = "";
  let showQuestionNotSolution = true;

  let started = false;
  let title = "";
  let readyComingUp = 0;
  let isTesting = false;
  let showTestIntroPopUp = false; //should only show this if the user has some memories from this lesson
  let startOrContinueTimedTest = "Start";
  let TestExample1 = "",
    TestExample2 = "",
    TestExample3 = "";
  let timerRunning = false;
  let startTime = 0;
  let showStartLearningPopup = false;

  //For the testing:
  // staging <- new -> memoriezed
  //export let newCount = 0;
  //export let memorizedCount = 0;

  loadMath().then(() => {
    // Now you can be sure that mathjs is loaded, and you can use parse and simplify globally
    //console.log("Math.js has been loaded.");
  });

  $: handleRouteChange(slug);
  //$: console.log("started " + started);
  //$: console.log("showInstructions " + showInstructions);
  //$: console.log("isShowHighscore: " + isShowHighScore);
  //$: console.log(`showHighscoreButton: ${isShowHighscoreButton}`);
  //$: console.log(`showHighscoreButton2: ${isShowHighscoreButton}`);
  //$: console.log(`InstructionComponent?=${InstructionComponent ? true : false} showInstructions=${showInstructions} started=${started}`);
  //$: console.log("notRemembered 2" + notRemembered);
  //$: console.log("secondsRemembered: " + secondsRemembered);
  //$: console.log("currentTask", currentTask);
  //$: console.log("finished: :" + finished);
  $: if (started && showQuestionNotSolution && inputElement) focusInput();
  //$: console.log("hints exist :" + hintExists);
  //$: comingUp = tasksLeft + available_questions;

  $: if (questionStr) {
    //console.log("new questionstring: " + questionStr);
    mathContent.set(questionStr);
    updateMathJax();
  }

  $: if (finished && authorized) {
    //achievedNewProgress = await LessonLearned();
    updateProgress();
  }

  //$: console.log(`slug: ${slug}`);
  $: isEquationEditor = slug == "derivatives" || slug == "integrals";
  $: finished = !currentTask;
  $: console.log(`finished=${finished}`);

  $: if (memories) {
    //We are also counting in onReadyChange
    countReadyComingUp();
  }

  $: if (isTesting) {
    //no more coming up, we have to switch to learning
    //Give message: //todo
    // Testing is finished!
    // You have memorized {memorized} items
    // Now you may continue to learn {staged} items
    // Ok button

    let readyComingUpForTesting = memories.filter((x: BatchMemory) => x.gateComponentId == -100).length;
    if (readyComingUpForTesting == 0) {
      isTesting = false;
      showStartLearningPopup = true;
    }
  }

  //$: console.log(startTime);

  let memorized = 0;
  let staged = 0;
  let levelComplete = false;

  let showLevelCompletePopup = false;
  let showLevelCompletePopupClosed = false;

  $: memorized = memories.filter((memory) => memory.gateComponentId === 100).length;
  $: staged = memories.filter((memory) => memory.gateComponentId === 0).length;

  $: levelComplete = memorized > 0 && memorized == memories.length && staged === 0;
  $: showLevelCompletePopup = levelComplete && !showLevelCompletePopupClosed;

  $: {
    //console.log(`memorized=${memorized} memories.length=${memories.length}`);
    //console.log(`showLevelCompletePopup=${showLevelCompletePopup} `);
    //console.log(`levelComplete=${levelComplete} `);
  }

  //$: console.log(`started=${started}`);

  //$: console.log("readyComingUp1 =", readyComingUp);

  //CallbackFor TestIntroPopup
  function onStartTimedTest() {
    showTestIntroPopUp = false;
    isTesting = true;

    //Force recount
    memories = [...memories];
  }

  function onStartLearning() {
    showTestIntroPopUp = false;

    //This is difficult. If we suddently wanted to test, it shouldn't affect the gatecomponentId etc
    //Let's say we are in the middle of learning. Some have gateComponentId = 5 for instance.
    //If we wanted to test, don't change this, unless we make a mistake, then we set the gateComponentId to 0
    //And it is only the first time we test we can set it to memorized

    //For now, we don't support tests more than once.
    //As long as some memories are new, we will asked if we want to do test or learning

    //If we want to test, then we go through one and one question until there are no new left.

    //from new (-100) to staged/wrong (0) not really an advantage
    memories.forEach((mem) => (mem.gateComponentId = 0));

    memories = [...memories];
    updateProgress();
  }

  async function updateProgress() {
    achievedNewProgressProperty = await LessonLearned(); // Await here

    updateRating();
    //console.log("Its ItsCorrect, after update rating");
  }

  async function updateMathJax() {
    if (typeof window !== "undefined" && window.MathJax) {
      await window.MathJax.typesetPromise();
    }
    await tick();

    if (window.MathJax) {
      await window.MathJax.typesetPromise();
    }
  }

  async function handleRouteChange(slug: string) {
    focusInput();
    authorized = await setCurrentLesson(slug);
    if (authorized) {
      loadHints();
    } else {
      //alert("user is not authorized for this level");
      navigate("/");
    }
  }

  interface LessonVM {
    name: string;
    title: string;
    description: string;
  }

  //using the mouse/fingers on the screen elements

  //Would be better that this is active only if this component is visible
  function onEnter() {
    $userinput = $userinput.replaceAll(",", ".");

    if ($userinput.length > 0) {
      //console.log();
      if ($userinput.charAt(0) == "." || $userinput.charAt(0) == ",") {
        $userinput = "0" + userinput;
      }
    }

    if (started) {
      if ($userinput == "") return;
      CheckAnswer();
    } else {
      StartGame();
    }
  }

  async function loadHints() {
    hintExists = false;
    try {
      const componentMap: any = {
        mathconstants3digits: () => import("../instructions/memory/Constants.svelte"),
      };

      if (componentMap[slug]) {
        let module1 = componentMap[slug];
        //console.log("Importing component module:", module1);

        const module = await componentMap[slug]();
        //console.log("Imported component module:", module);
        InstructionComponent = module.default;
        //console.log("InstructionComponent set to:", InstructionComponent); // Log the component itself
        hintExists = true;
      } else {
        console.error("Component not found for slug:", slug);
      }
    } catch (error) {
      console.error("Error loading component:", error);
    }
  }

  function NextTask() {
    currentTask = GetNextTask();

    showCurrentTask();
  }

  class StartGamePost {}

  async function StartGame() {
    let lessonname = lessonData.name;
    let url = "BatchCrammer/StartGame";

    let stargGamePost = new StartGamePost();

    //startTime = await fetchPost<boolean, StartGamePost>(url, lessonData);
    var startTimeDate = await fetchPost<Date, StartGamePost>(url, lessonData);
    //startTime = startTimeDate.getSeconds();
    console.log(startTimeDate);
    //startTime = startTimeDate.getTime();

    const dateObject = new Date(startTimeDate);

    // Now you can use Date methods like getSeconds() or getTime()
    console.log(dateObject);
    startTime = dateObject.getTime();

    NextTask();

    started = true;

    setTimeout(() => {
      focusInput();
    }, 0);

    timerRunning = true;
    //startTime = $secondTimer;
    //startTime = dateUtc.getCurrentUtc();
    // startTime = DateUtc.utcNow();
  }

  function CheckAnswer() {
    //Testing:

    //newCount--;

    let IsCorrect = false;

    if (currentTask != null && currentTask!) {
      var decodedAnswer = "";

      if (currentTask.answer) {
        decodedAnswer = atobUtf8(currentTask.answer);
      }

      if ($userinput == decodedAnswer || simplifiedEqual($userinput, decodedAnswer)) {
        toast.push("Correct " + decodedAnswer, {});
        ItsCorrect(currentTask.translationId);
        IsCorrect = true;
        $showInstructions = false;

        //Testing:
        //memorizedCount++;
      } else {
        const toastId = toast.push("Should be " + decodedAnswer + " for " + currentTask.question, {
          classes: ["error"],
          duration: 3000,
        });
        ItsWrong(currentTask.translationId);

        //Testing:
      }
      $userinput = "";
    }
  }

  function Wrong(translationId: number) {
    let memory = memories.find((mem) => mem.translationId === translationId);
    if (!memory) {
      throw new Error("No memory of this word");
    }
    memory.secondsRemembered = 0;
    //memory.userAnswer = $userinput;
    memory.seen = new Date(); //TODO FIXME (MAYBE)
    memory.gateComponentId = 0;
    memory.hintNeeded = $showInstructions;
    memory.wrongCount++;
    updateProgress(); //saves to the server

    currentTask = GetNextTask();
    showCurrentTask();

    // Reassign the array to make it reactive
    memories = [...memories];
    //console.log("memories=", memories);

    $rating--;
  }

  function ItsCorrect(translationId: number) {
    let memory = memories.find((mem) => mem.translationId === translationId);
    if (!memory) {
      throw new Error("No memory of this word");
    }
    let now = new Date(); //TODO FIXME (MAYBE)
    memory.secondsRemembered = 0; //we changed this a bit, we start from 0 at each gate
    memory.seen = now;
    // memory.userAnswer = $userinput;
    memory.hintNeeded = $showInstructions;
    memory.correctCount++;

    if (memory.gateComponentId == -100) {
      //new
      memory.gateComponentId = 100; //memorized
    } else if (memory.gateComponentId == 19) {
      //1 year
      //new
      memory.gateComponentId = 100; //memorized
    } else {
      //We should increate the gateCompoentId
      memory.gateComponentId++;
    }
    updateProgress(); //saves to the server. the rating should be updated after we have updated

    currentTask = GetNextTask();
    showCurrentTask();

    // Reassign the array to make it reactive
    memories = [...memories];
    //console.log("memories=", memories);

    //$rating++;

    console.log("Its ItsCorrect, now update rating");
  }

  async function ItsWrong(id: number) {
    showQuestionNotSolution = false;
    progressBarWidth = "0%";
    await tick(); // Let Svelte update the DOM
    progressBarWidth = "100%";
    setTimeout(() => {
      showQuestionNotSolution = true;
      progressBarWidth = "0%";
      focusInput();
      Wrong(id);
    }, 3000); // Adjust to match the CSS animation duration
  }

  function showCurrentTask() {
    //console.log("**showCurrentTask");
    //console.log("CurrentTask=", currentTask);
    questionStr = "";

    if (currentTask) {
      //console.log("if(CurrentTask)= true");
      questionStr = currentTask.question;
      if (questionStr) {
        const splitArray = questionStr.split("\n"); //Questios with \n have some infoText, a second part of the question
        questionStr = splitArray[0];
        if (splitArray.length == 2) infoText = splitArray[1];
      }
    } else {
      //started = false;
    }
  }

  onMount(() => {
    $showInstructions = false;
    window.addEventListener("keyup", globalKeyupHandler);
  });

  onDestroy(() => {
    window.removeEventListener("keyup", globalKeyupHandler);

    //if(finished && authorized )
    //LessonLearned();
  });

  function globalKeyupHandler(event: KeyboardEvent) {
    if (event.key === "Enter") {
      onEnter();
    }
  }

  let progressBarWidth = "0%"; // Start with the progress bar empty

  async function setCurrentLesson(slug: string): Promise<boolean> {
    var clientUrl = "/BatchCrammer/SetCurrentLesson/" + slug; //should be the same as for crammer
    let authorizedVm = await fetchGet<AuthorizedForLessonVm>(clientUrl);
    //authorized = authorizedVm.isAuthorized;

    if (!authorizedVm.isAuthorized) {
      //alert("Not authorized for this lesson.");
      $showDailyLimitAlert = true;
      $minutesUntilAuthorized = authorizedVm.minutesUntilAuthorized;
    } else {
      //get title and description
      clientUrl = "/BatchCrammer/GetCurrentLesson/" + slug;
      let currentLesson = await fetchGet<LessonVM>(clientUrl);

      lessonDescription = currentLesson.description;
      title = currentLesson.title;

      clientUrl = "/BatchCrammer/GetLesson/" + slug;
      lessonData = await fetchGet<BatchLessonData>(clientUrl);

      //Before we have started, we might have spent some time earlier on this lesson
      // var startTimeDate = await fetchPost<Date, StartGamePost>(url, lessonData);
      //startTime = startTimeDate.getSeconds();
      //console.log(startTimeDate);
      //startTime = startTimeDate.getTime();
      //const dateObject = new Date(lessonData.startTime);

      // Now you can use Date methods like getSeconds() or getTime()
      //console.log(dateObject);
      //startTime = dateObject.getTime();
      if (lessonData.startTime) {
        //startTime = lessonData.startTime.getTime();
        let str: any = lessonData.startTime; //type is wrong, it was just a string
        const dateObject = new Date(str);
        startTime = dateObject.getTime();
      }

      adjustSecondsRemembered();

      countReadyComingUp();
      // if (lessonData.translations) {
      // 	tasks = lessonData.translations;
      // }
      if (lessonData.memories != undefined) {
        memories = lessonData.memories;

        if (memories.length > 0) {
          TestExample1 = memories[0].question ?? "";
        }
        if (memories.length > 1) {
          TestExample2 = memories[1].question ?? "";
        }
        if (memories.length > 2) {
          TestExample3 = memories[2].question ?? "";
        }

        showTestIntroPopUp = false;

        let haveNewMemory = memories.find((mem) => mem.gateComponentId == -100);
        if (haveNewMemory) {
          showTestIntroPopUp = true;
        }
      }
    }

    return authorizedVm.isAuthorized;
  }

  function adjustSecondsRemembered() {
    //const now : number = Date.now();
    const now = DateUtc.utcNow();

    lessonData.memories?.forEach((mem) => {
      const seen = new Date(mem.seen); // Ensure `x.seen` is a Date object

      //mem.secondsRemembered = (nowUtc - seen.getTime()) / 1000;
      mem.secondsRemembered = (now - seen.getTime()) / 1000;
    });
  }

  function focusInput() {
    if (inputElement) {
      inputElement.focus();
      //console.log("Focus attempted on inputElement.");
    } else {
      //console.log("inputElement is not available.");
    }
  }
  function restart(): any {
    location.reload();
  }

  function showHints(): any {
    //hintsOpen = !hintsOpen;
    $showInstructions = true;
    //if ($showInstructions) {
    // 	hintDisplayed = true;
    // 	if (currentTask.hintDisplayed == false) {
    // 		$rating -= 10;
    // 		currentTask.hintDisplayed = true;
    // 	}
    // }
    //  $rating--;
    //}
  }

  async function forgetLesson(event: MouseEvent & { currentTarget: EventTarget & HTMLButtonElement }) {
    var clientUrl = "/BatchCrammer/ForgetLesson/";
    await fetchGet<void>(clientUrl);
    location.reload();
  }

  function GetNextTask(): BatchMemory | null {
    //We check if there are old enough memories, take the best, according to sortval
    //If not, just take the next unseen
    //If no available memory and no unseen there are no new for now

    //New algorithm:
    //0.
    //If we are testing, take any new memory

    //1.
    //For all the old memories, in gates >0, take the ones that are ready
    //sort by gateid
    //take the one with the biggest gateid

    //2
    //if we don't have ready old memories
    //take one we have seen before, that was wrong and is now in the staging area

    //3.
    //if we still don't have any,
    //take one from the staging area.

    let memTask: BatchMemory | null = null;

    //0.
    if (isTesting) {
      let gatedMems = memories.filter((x: BatchMemory) => x.gateComponentId == -100);
      if (gatedMems.length > 0) {
        memTask = gatedMems[0];
        return memTask;
      }
    }

    //1.
    let gatedMems = memories
      .filter((x: BatchMemory) => x.gateComponentId > 0 && x.secondsRemembered > WaitTimes(x.gateComponentId))
      .sort((a: BatchMemory, b: BatchMemory) => b.gateComponentId - a.gateComponentId);

    if (gatedMems.length > 0) {
      memTask = gatedMems[0];
      return memTask;
    }

    //2.
    let stagedSeen = memories
      //.filter((x: BatchMemory) => x.gateComponentId == 0 && x.seen.getFullYear() < 2100)
      .filter((x: BatchMemory) => {
        const seenDate = new Date(x.seen); // Ensure `x.seen` is a Date object
        return x.gateComponentId === 0 && seenDate.getFullYear() < 2100;
      })
      //.sort((a: BatchMemory, b: BatchMemory) => b.seen.getTime() - a.seen.getTime());
      .sort((a: BatchMemory, b: BatchMemory) => {
        const seenDateA = new Date(a.seen); // Ensure `x.seen` is a Date object
        const seenDateB = new Date(b.seen); // Ensure `x.seen` is a Date object
        return seenDateB.getTime() - seenDateA.getTime();
      });

    if (stagedSeen.length > 0) {
      memTask = stagedSeen[0];
      return memTask;
    }

    //3
    let staged = memories.filter((x: BatchMemory) => x.gateComponentId == 0);
    if (staged.length > 0) {
      memTask = staged[0];
      return memTask;
    }

    return memTask;
  }

  function countReadyComingUp() {
    //We check if there are old enough memories, take the best, according to sortval
    //If not, just take the next unseen
    //If no available memory and no unseen there are no new for now

    //New algorithm:
    //0 the new
    //we cannot only do this if isTesting, because we want to know the number before we have decided to learn or test
    let readyComingUpForTesting = memories.filter((x: BatchMemory) => x.gateComponentId == -100).length;
    //Also compute if we are starting (all are -100) or if we continue, some are not -100
    startOrContinueTimedTest = "Continue";
    if (readyComingUpForTesting == memories.length) {
      startOrContinueTimedTest = "Start";
    }

    if (isTesting) {
      readyComingUp = readyComingUpForTesting;
      return;
    }

    //1.
    //For all the old memories, in gates >0, take the ones that are ready
    //sort by gateid
    //take the one with the biggest gateid

    //2
    //if we don't have ready old memories
    //take one we have seen before, that was wrong and is now in the staging area

    //3.
    //if we still don't have any,
    //take one from the staging area.

    //1.
    let gatedMems = memories.filter((x: BatchMemory) => x.gateComponentId > 0 && x.secondsRemembered > WaitTimes(x.gateComponentId));

    //let gatedMemsCount = gatedMems.length;

    //2.
    // let stagedSeen = memories
    // 	//.filter((x: BatchMemory) => x.gateComponentId == 0 && x.seen.getFullYear() < 2100)
    // 	.filter((x: BatchMemory) => {
    // 		const seenDate = new Date(x.seen); // Ensure `x.seen` is a Date object
    // 		return x.gateComponentId === 0 && seenDate.getFullYear() < 2100;
    // 	})
    // 	//.sort((a: BatchMemory, b: BatchMemory) => b.seen.getTime() - a.seen.getTime());
    // 	.sort((a: BatchMemory, b: BatchMemory) => {
    // 		const seenDateA = new Date(a.seen); // Ensure `x.seen` is a Date object
    // 		const seenDateB = new Date(b.seen); // Ensure `x.seen` is a Date object
    // 		return seenDateB.getTime() - seenDateA.getTime();
    // 	});

    // let stagedSeenCount = stagedSeen.length;

    //3
    let staged = memories.filter((x: BatchMemory) => x.gateComponentId == 0);
    //let stagedCount = staged.length;

    //readyComingUp = gatedMemsCount + stagedCount;
    readyComingUp = gatedMems.length + staged.length || 0; // Ensure `readyComingUp` is a number

    //console.log("Updated readyComingUp to", readyComingUp);
    //This didn't work reactively
    if (readyComingUp == 0) {
      //console.log("readyComingUp == 0, setting started = false");
      started = false;
    }
  }

  async function LessonLearned(): Promise<boolean> {
    // have to await promise, try without
    //TODO: remove those memories we have not seen
    var url = "BatchCrammer/LessonLearned";
    var ok = await fetchPost<boolean, BatchLessonData>(url, lessonData);
    return ok;
  }

  function onReadyChange(id: number, readyCount: number) {
    //console.log(`keypadmemm onReadyChange id=${id} readyCount=${readyCount}`);
    //updateProgress();
    //finished = false; //Maybe we should calculate this instead

    updateProgress();
    countReadyComingUp();

    if (finished) NextTask();
  }

  function onTestSwitchHange(isTestingOn: boolean): void {
    //console.log("onTestSwitchChange, isTesting=", isTestingOn);
    isTesting = isTestingOn;
  }
</script>

{#if showLevelCompletePopup}
  <LevelCompletePopup onOk={() => (showLevelCompletePopupClosed = true)}></LevelCompletePopup>
{/if}

<!-- {#if 0 && finished}
  <GameUnlocked {achievedNewProgress}></GameUnlocked>
{/if}

{#if 0 && achievedNewProgress}
  <GameUnlocked {achievedNewProgress}></GameUnlocked>
{/if} -->

<GameUnlocked {achievedNewProgressProperty}></GameUnlocked>

{#if showStartLearningPopup && !levelComplete}
  <TestOutroPopup
    {memorized}
    {staged}
    onOk={() => {
      showStartLearningPopup = false;
    }}
  />
{/if}

{#if showTestIntroPopUp}
  <TestIntroPopUp
    {onStartTimedTest}
    {onStartLearning}
    example1={TestExample1}
    example2={TestExample2}
    example3={TestExample3}
    startOrContinue={startOrContinueTimedTest}
  ></TestIntroPopUp>
{/if}

<!-- <h2>Coming up: {comingUp}</h2> -->
<div class="stair-keypad">
  <div class="score-button">
    <button on:click={() => navigate("/home")} class="bttn home-button">
      <img
        alt="home"
        src="/homeicon.svg"
        style="display: inline-block; vertical-align: middle; background-color:white; margin-bottom:2px;"
        width="20px"
        height="20px"
      />Home
    </button>
    <button on:click={() => restart()} class="bttn">
      <img
        alt="restart"
        src="/restart.svg"
        style="display: inline-block; vertical-align: middle;"
        width="20px"
        height="20px"
      />Restart</button
    >
    <button on:click={forgetLesson} class="forget-lesson bttn">Forget Lesson</button>
    <div class="learnswitch-button">
      <SwitchButton {isTesting} onChange={onTestSwitchHange}></SwitchButton>
    </div>
  </div>
  <div class="level">
    <h1 class="custom-h1">
      <p class="title">{lessonDescription}</p>
    </h1>
  </div>
  <MemoryTaskView Memories={memories} {onReadyChange}></MemoryTaskView>

  {#if isShowHighScore}
    <!-- <HiScore {slug}></HiScore> -->
  {/if}

  {#if isGameOver}
    <!-- <GameOver {results}></GameOver> -->
  {:else}
    <div class="keypad">
      <div class="keypad-content">
        <div class="flex-box2">
          <div class="task-timer">
            <div>Coming up: {readyComingUp}</div>
            {#if isTesting}
              <MemoryTimer StartTime={startTime} {timerRunning}></MemoryTimer>
            {/if}
          </div>
        </div>
        <div class="container">
          <div>
            <div class="background">
              {#if started}
                <div>
                  <div class="flex-box">
                    {#if levelComplete}
                      <div class="small-font">{@html started ? "Level Complete!" : $mathContent}</div>
                    {:else}
                      <div class="small-font">{@html $mathContent}</div>
                    {/if}
                  </div>
                  <div class="info-text">
                    {infoText}
                  </div>
                </div>
              {:else}
                {#if readyComingUp > 0}
                  {#if isTesting}
                    <div class="flex-box">Press "Enter" to start timed test</div>
                  {:else}
                    <div class="flex-box">Press "Enter" to start learning</div>
                  {/if}
                {:else}
                  <div class="flex-box">
                    <div class="small-font">Waiting for long term memory transfer</div>
                  </div>
                {/if}
                <!-- comes out in the flexbox -->
                <!-- <div class="info-text">
									{infoText}
								</div> -->
              {/if}
            </div>
          </div>

          <div class="input-container">
            {#if started}
              {#if showQuestionNotSolution}
                {#if hintExists}
                  <input
                    type="text"
                    class="my-input"
                    bind:value={$userinput}
                    bind:this={inputElement}
                    readonly={$isMobile}
                    placeholder="?-button for hints"
                  />
                {:else}
                  <!-- without the placeholder -->
                  <input type="text" class="my-input" bind:value={$userinput} bind:this={inputElement} readonly={$isMobile} />
                {/if}
              {:else}
                <div class="correct-hint" id="correctHint">
                  <div class="solution">
                    {#if currentTask && currentTask.answer}
                      {atob(currentTask.answer)}
                    {/if}
                  </div>
                  <div class="progress-container">
                    <div class="progress-bar" style="width: {progressBarWidth};"></div>
                  </div>
                </div>
              {/if}
            {/if}
          </div>
        </div>
        {#if isEquationEditor}
          <MathView eq={$userinput}></MathView>
          <KeysEq {onEnter}></KeysEq>
        {:else}
          <Keys {onEnter} {showHints} showHintsVisible={started && hintExists}></Keys>
        {/if}
      </div>
      {#if InstructionComponent && $showInstructions && started}
        <svelte:component this={InstructionComponent} A={paramA} B={paramB} UserInput={userinput} QuestionStr={questionStr} />
      {:else if started && hintExists}
        {#if started && hintExists}
          <!-- <h3>started && hintExists</h3>
          <button on:click={() => showHints()} class="hint">
            {#if $showInstructions}Close Hint
            {:else}Show Hint!
            {/if}</button> -->
        {/if}
      {/if}
    </div>
  {/if}
</div>

<style>
  /* .score-content {
		display: flex;
		flex-direction: column;
		margin-top: 4rem;
	} */
  .keypad {
    display: flex;
    flex-direction: row;
    position: relative;
  }
  .keypad-content {
    display: flex;
    flex-direction: column;
  }
  @keyframes glow-border {
    0% {
      border-color: white;
      box-shadow: 0 0 10px white;
    }
    100% {
      border-color: white;
      box-shadow: 0 0 20px white;
    }
  }

  .bttn {
    font-size: 12px;
    flex: 1 1 10%;
    margin: 5px;
    width: 150px;
    text-align: center;
    box-sizing: border-box;
    height: 30px;
  }
  .forget-lesson {
    z-index: 10;
  }

  .score-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 470px;
  }
  @media (max-width: 470px) {
    .bttn {
      font-size: 9px;
      flex: 1 1 10%;
      height: 30px;
    }
    .score-button {
      width: auto;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
  /* .keypad {
		display: flex;
		top: 5%;
		left: 0;
		right: 0;
		bottom: 0;
	} */
  @media (max-width: 600px) {
    .keypad {
      width: auto;
      padding: 0;
    }
    .stair-keypad {
      display: flex;
      flex-direction: column;
      margin-top: 4rem;
    }
  }

  .level {
    width: 430px;
    color: white;
    text-align: center;
    font-size: x-large;
    font-weight: bold;
  }

  .background {
    display: flex;
    width: 425px;
    height: 180px;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.274);
    border: 1px solid white;
  }

  .flex-box {
    color: #fff;
    font-size: 40px;
    font-weight: bolder;
    text-align: center;
  }

  .input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    height: 50px;
  }

  .my-input {
    width: 300px;
    height: 50px;
    background-color: #fff;
    border: 3px solid gray;
    color: #000;
    font-size: 30px;
    text-align: center;
    line-height: 50px;
  }

  .my-input:focus {
    outline: none;
    border-color: blue;
  }

  .my-input::placeholder {
    font-size: 20px;
  }
  .flex-box2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .task-timer {
    display: flex;
    font-size: 25px;
    width: 430px;
    height: 50px;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.4);
  }

  button {
    width: 75px;
    height: 75px;
    margin: 5px;
    border-radius: 10px;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-size: 40px;
    margin: 10px;
    padding: 0;
  }
  .custom-h1 {
    padding: 0;
    margin-top: 30px;
    width: auto;
  }
  .info-text {
    color: white;
  }
  .correct-hint {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 300px;
    height: 50px;
    background-color: red;
    border: 1px solid white;
    color: white;
    justify-content: center;
    align-items: center;
    animation: glow 1s infinite alternate;
  }
  @keyframes glow {
    0% {
      background-color: lightgreen;
      box-shadow: 0 0 5px 3px lightgreen;
    }
    100% {
      background-color: red;
      box-shadow: 0 0 10px 5px red;
    }
  }
  .progress-container {
    display: flex;
    position: absolute;
    bottom: 0;
    left: -10;
    width: 300px;
    height: 5px;
    margin-top: 5px;
    border-radius: 0 5px 5px 0;
    overflow: hidden;
  }
  .progress-bar {
    width: 0%; /* Start width, maybe not needed*/
    height: 5px;
    background-color: white;
    border-radius: 0 5px 5px 0;
    transition: width 3s ease;
  }

  .solution {
    font-size: 20px;
  }
  /* .hint {
    background: none;
    border: 2.5px solid yellow;
    color: #fff;
    width: 70px;
    height: 60px;
    animation: pulse 1s infinite;
    margin-top: 220px;
  } */
  /* .to-do {
		display: flex;
		flex-direction: row;
		font-size: 10px;
		color: white;
		height: 80px;
		padding: 10px;
		left: 10px;
		z-index: 1;
	} */

  /* .task {
		display: flex;
		flex-direction: row;
	} */

  /* .box::before {
		content: attr(data-number);
		position: absolute;
		bottom: 100%;
		right: calc(100% - 15px);
		padding: 2px;
		border-radius: 2px;
		font-size: 8px;
	} */
  /* .task-left {
		display: flex;
		flex-direction: row;
	} */
  .stair-keypad {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
  }
  /* .repeat-available {
		display: flex;
		width: 100%;
		flex-direction: column;
		z-index: 1;
	}
	.repeat-time {
		font-size: 10px;
		width: 50px;
	}
	.time {
		display: flex;
		flex-direction: row;
		gap: 40px;
	}
	.five-min {
		gap: 50px;
	}
	.thirty-min {
		gap: 50px;
	}
	.available {
		margin-top: -20px;
		color: gold;
	} */
  .small-font {
    font-size: 20px;
  }

  .my-input::placeholder, 
.my-input::-webkit-input-placeholder, /* Chrome, Safari, Edge */
.my-input::-moz-placeholder,         /* Firefox 19+ */
.my-input:-ms-input-placeholder,     /* IE 10-11 */
.my-input:-moz-placeholder {
    /* Older Firefox versions */
    color: gray;
    font-size: 6px; /* Adjust size as needed */
  }
</style>
